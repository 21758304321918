let TMs = {

    TSession: "session",
    MSessionLogin: "login",
    MSessionLogout: "logout",
    MSessionSaveBrowserFinger: "save_browser_finger",

    TVisit: "visit",
    MVisitGetMobileRegCode: "getMobileRegCode",
    MVisitAppStart: "appStart",
    MVisitAppFirstVisit: "appFirstVisit",

    TLogger: "logger",
    MLoggerCreate: "create",

    TTracking: "tracking",
    MTrackingGetVisitKey: "getVisitKey",

    TUser: "user",
    MUserGetUserInfo: "getUserInfo",
    MUserCreateRegCode: "createRegCode",
    MUserLoginByCode: "loginByCode",
    MUserLogout: "logout",
    MUserLoginByGoogle: "loginByGoogle",
    MUserLoginByEmail: "loginByEmail",
    MUserLoginByPw: "loginByPw",
    MUserUpdatePw: "updatePw",

}

export default TMs;

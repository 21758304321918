import {EventDispatcher} from "../../../../libs/fanfanlo/events/EventDispatcher";
import {MainModelBase} from "../../../../mc/MainModelBase";

import FingerprintJS, {GetOptions} from '@fingerprintjs/fingerprintjs'

const browderFingerKeyName = "browserFinger";
const browderFingerVersionKeyName = "browserFingerVersion";
const browderFingerVersion = 1;
export class MainModel extends MainModelBase{
    static events = {
        addWaitingMember:"addWaitingMember",
    }
    static instance:MainModel;
    static tabEvents = {
        tabChanged:"tabChanged",
    }

    readonly href = window.location.href
    browserFinger:string|undefined;
    // waitingMembers:any[] = [];
    constructor() {
        super();
        MainModel.instance = this;
    }

    async getBrowserFinger () {
        const fpPromise = FingerprintJS.load();
        const fp = await fpPromise;
        // 版本不同？https://github.com/fingerprintjs/fingerprintjs/issues/509
        // const options:GetOptions = {
        //     excludes: {userAgent: true}
        // }
        const result = await fp.get();
        this.browserFinger = result.visitorId;
    }
    async getBrowserFingerInfo() {
        return { now: this.browserFinger, old: this.getSavedBrowserFinger() }
    }
    getSavedBrowserFinger = ()=> {
        const v = localStorage.getItem(browderFingerVersionKeyName);
        if(!v || (v && v.toString() !== browderFingerVersion.toString())) {
            localStorage.removeItem(browderFingerKeyName);
        }
        return localStorage.getItem(browderFingerKeyName) || "";
    }
    saveBrowderFinger = () => {
        localStorage.setItem(browderFingerVersionKeyName, browderFingerVersion.toString());
        localStorage.setItem(browderFingerKeyName, this.browserFinger || "");
    }
    getSavedShareMoneyInfo = ()=> {

    }
}

export const mainModel = new MainModel();
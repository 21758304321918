import {EventDispatcher} from "../../../../libs/fanfanlo/events/EventDispatcher";
import {DataEvent} from "../../../../libs/fanfanlo/events/DataEvent";


export class User extends EventDispatcher{
    nickname:string|undefined;
    copyFromJson =(data:any)=>{

    }
}

export class UserModel extends EventDispatcher{
    static events ={
        logginFetching:"logginFetching",
        logginComplete:"logginComplete",
        logginLogged:"logginLogged", // 有用户存在
        logout:"logout",
        fetchLogin:"fetchLogin:,"
    }
    public static readonly eventUserConnectComplete = "eventUserConnectComplete";
    public static readonly eventUserBrowserFingerSaved = "eventUserBrowserFingerSaved";
    private _userConnecting:boolean = false;
    static readonly eventUserConnectingChanged = "eventUserConnectingChanged";
    userConnecting = false;
    userFirstConnected = false;
    user = new User();

}

export const userModel = new UserModel();
import {EventDispatcher} from "../libs/fanfanlo/events/EventDispatcher";
import {androidJsGlobalName} from "../android/jsbridge.js";
import {storage} from "../common/storage/storage";
import Member from "../libs/fanfanlo/remotes/Member";
import TMs from "../remotes/TMs";
import {ClientJS} from "clientjs";
import {MainModelBase} from "./MainModelBase";
import {DataEvent} from "../libs/fanfanlo/events/DataEvent";
import {pbkdf2} from "pbkdf2";

const an = androidJsGlobalName;
export class VisitModel extends EventDispatcher{
    static events ={
        logginFetching:"logginFetching",
        logginComplete:"logginComplete",
        logginLogged:"logginLogged", // 有用户存在
        logout:"logout",
        logChanged:"logChanged",
        fetchLogin:"fetchLogin",
        startInfoFetched:"startInfoFetched",
        userInfoFetched : "userInfoFetched",
    }
    static eventUserConnectComplete = "eventUserConnectComplete";
    constructor() {
        super();
        this.logged = false;
        this.visitInfo = {device_info_uuid: '', client_user_uuid: '', client_ticket_uuid: ''};
        this.visitInfo = null;
        this.globalUser = {mobile:"", email:"", nickname:""};
        this.globalUser = null;
        this.appUser = {}
        this.appUser = null;
        this.userInfoFetching = false;
        this.userFirstConnected = false;
    }

    _runLogin = ()=>{
        let user_token = storage.getItem("user_token")
        if(!user_token)return;//没有写入user_token
        if(this._loginMember)return;
        this.userInfoFetching = true;
        const body = {user_token};
        let member = this._loginMember = new Member(TMs.TUser, TMs.MUserGetUserInfo, body, (res)=>{
            // console.log(`tms.TSession, tms.MSessionLogin res is2`, res);
            // userModel.user.copyFromJson(res.user);
            // this.user = user;
            if(!res.global_user_info){
                f7.dialog.alert("登录失败")
                return
            }
            this.logged = true
            this.globalUser = res.global_user_info;
            this.dispatchEvent(new DataEvent(VisitModel.events.userInfoFetched))
            this.dispatchEvent(new DataEvent(VisitModel.events.logChanged))
        }, (member)=>{
            console.log(`tms.TSession, tms.MSessionLogin net error is`, member.netError, member.netErrorInfo);
        }, (member)=>{
            console.log(`tms.TSession, tms.MSessionLogin data error is`, member.dataError, member.dataErrorInfo);
        }, (member) =>{
            this._loginMember = undefined;
            this.userInfoFetching = false;
            this.userFirstConnected = true;
            // console.log(`tms.TSession, tms.MSessionLogin complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
            this.dispatchEvent(new DataEvent(VisitModel.eventUserConnectComplete));

            // this._checkUpdateBrowserFinger();
        }, null, true, true);
        let event = new DataEvent(MainModelBase.events.addWaitingMember, member.send);
        MainModelBase.instance.dispatchEvent(event);
    }
    start=()=>{
        getBrowserFinger();
    }
    logout =()=>{
        storage.removeItem("user_token", "")
        this.logged = false;
        this.globalUser = null;
        this.appUser = null;
        this.dispatchEvent(new DataEvent(VisitModel.events.logChanged))
        // let member = new Member(TMs.TUser, TMs.MUserLogout, {}, (res)=>{
        //     console.log(`TMs.TUser, TMs.MUserGetUserInfo res is`, res)
        //
        // }, (member)=>{
        //     console.log(`TMs.TUser, TMs.MUserGetUserInfo net error is`, member.netError, member.netErrorInfo);
        // }, (member)=>{
        //     console.log(`TMs.TUser, TMs.MUserGetUserInfo data error is`, member.dataError, member.dataErrorInfo);
        // }, (member) =>{
        //     console.log(`TMs.TUser, TMs.MUserGetUserInfo complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        // }, null, true, true);
        // mainModelBase.addWaitingMember(member.send);
    }
    tryLogin =()=>{
        this._runLogin();
    }
    static createPw(code, cb){
        // pbkdf2('secret', 'salt', 100000, 64, 'sha512', (err, derivedKey) => {
        //     if (err) throw err;
        //     console.log(derivedKey.toString('hex'));  // '3745e48...08d59ae'
        // });
        let saltPrefix = "IYD7uo084c*2#QkjgO@JXDEjCs^G!EI0PC2rV^ibG7^!!gp9rJg%7yhHO#Y#Ilv8%ccaw1XTuWLyzwdaOJUzBm%65bI6yOLcTf&";
        let saltSuffix = "a!#lQ6I@cCurG4sqexQ3R*Z63kCVl3^vj8!zEaU9YaHUMIh1An0%jpkondiL&WFJz5bZT7lXP4b2cP7FQSjTPzBt#v1D2J@qBmF";
        pbkdf2(code, saltPrefix, 100, 128, "sha512", (err, derivedKey)=>{
            if (err) {
                alert("failed on encrypt password");
                cb(code)
                return;
            }
            console.log("dddddddddddddd", derivedKey.toString("hex"))
            pbkdf2(derivedKey.toString('hex'), saltSuffix, 100, 128, 'sha512', (err, derivedKey) => {
                if (err) {
                    alert("failed on encrypt password");
                    cb(code)
                    return;
                }
                cb(derivedKey.toString("hex"))
                console.log(derivedKey.toString('hex'));  // '3745e48...08d59ae'
            });
        });
    }
}

// {is_ok: true, msg: '', device_info_uuid: '59cb016cb40a11ee84828795009572d1', client_user_uuid: 'cedfece2b39c11ee8a018fc6ff145674', client_ticket_uuid: '08ad61c8b41711eea9cbb3b8f6f2fe5d'}

export const visitModel = new VisitModel();


function getBrowserFinger(){
    window[an] ? androidStart() : browserStart()
}

function browserStart(device_id){
    const client = new ClientJS();
    const finger = client.getFingerprint().toString();
    getTicket(finger, device_id);
}
function androidStart(){
    browserStart("androidgetid");
}


// // table device_info.uuid
// ClientId string `json:"client_id"`
// // table device_info.device_finger
// DeviceId string `json:"device_id"`
// // table device_info.browser_finger
// BrowserId string `json:"browser_id"`
// // 已登录用户的登录凭证，未登录用户该字段为空。
// UserToken string `json:"user_token"`
// // table device_user.uuid
// ClientUserId string `json:"client_user_id"`

function getTicket(browser_id, device_id ){
    let fetched = false;
    let client_user_uuid = storage.getItem("client_user_uuid");
    let client_ticket_uuid = storage.getItem("client_ticket_uuid");
    let device_info_uuid = storage.getItem("device_info_uuid");
    let user_token = storage.getItem("user_token");
    let body = {
        browser_id,
        device_id,
        device_info_uuid,
        client_ticket_uuid,
        user_token,
        client_user_uuid
    }
    let member = new Member(TMs.TVisit, TMs.MVisitAppStart, body, (res)=>{
        // let r = {"data":{"is_ok":true,"msg":"","client_user_uuid":"cedfece2b39c11ee8a018fc6ff145674","client_ticket_uuid":"fc96926eb39f11eeaf3ee79a92de61e1"},"is_ok":true,"is_complate":false}

        if(res.client_user_uuid){
            storage.setItem("client_user_uuid", res.client_user_uuid);
        }
        if(res.client_ticket_uuid){
            storage.setItem("client_ticket_uuid", res.client_ticket_uuid);
        }
        if(res.device_info_uuid){
            storage.setItem("device_info_uuid", res.device_info_uuid);
        }
        if(res.user_token){
            storage.setItem("user_token", res.user_token);
        }
        fetched = true;
        visitModel.visitInfo = res;
        visitModel.dispatchEvent(new DataEvent(VisitModel.events.startInfoFetched));
    }, ()=>{

    }, ()=>{

    }, ()=>{

    }, null, true, true);
    member.send()
}

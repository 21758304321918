
import * as _ from "lodash";
import {DataEvent} from "../events/DataEvent";
const maxRetryCount = 5;
export class Member{
  constructor(team, member, body, onSuccess, onNetError, onDataError, onComplete, dispatcher, isCentralHandleNetError, isCenTralHandleDataError){
    this.team = team;
    this.member = member;
    this.body = body || {};
    this.onSuccess = onSuccess;
    this.onNetError = onNetError;
    this.onDataError = onDataError;
    this.onComplete = onComplete;
    this.maxRetryCount = maxRetryCount;
    this.retryCount = 0;
    this.dataError = null;
    this.dataErrorInfo = "";
    this.netError = null;
    this.netErrorInfo = "";
    this._isNetOk = false;
    this._isDataOk = false;
    this._isTotallyOk = false;
    this._res = null;
    this.dispatcher = dispatcher;
    this._response = null;
    this._responseText = null;
    this.isCentralHandleNetError = isCentralHandleNetError;
    this.isCentralHandleDataError = isCenTralHandleDataError;
    this.successEventType = `member_event_${this.team}_${this.member}_success`;
    this.netErrorEventType = `member_event_${this.team}_${this.member}_net_error`;
    this.dataErrorEventType = `member_event_${this.team}_${this.member}_data_error`;
    this.completeEventType = `member_event_${this.team}_${this.member}_complete`;
  }
  get isNetOk(){return this._netIsOk}
  get isDataOk(){return this._isDataOk;}
  get isTotoallOk(){return this._isTotallyOk;}
  get res(){return this._res;}
  get responseErrorMessage() {
    console.log('resErrorMessage is', this.response);
    if (!this._response) return "";
    if (this._response.err_msg) return this._response.err_msg;
    if (!this._response.data) return "";
    if (this._response.data.msg) return  this._response.msg;
    return "";
  }
  getDispatcher = () =>{
    return this.dispatcher || Member.dispatcher;
  }
  getSuccessEvent = () =>{
    return Member.getSuccessEvent(this.team, this.member);
  }
  send = (gateway, body, headerSetterList) =>{
    let data = body || this.body;
    gateway = gateway || Member.gateway;
    let endpoint = `${gateway}${this.team}/${this.member}`;
    headerSetterList = headerSetterList || Member.headerSetterList;
    this.body = data;
    this.gateway = gateway;
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("abort", this._onXhrAbort.bind(this));
    xhr.addEventListener("load", this._onXhrLoad.bind(this));
    xhr.addEventListener("progress", this._onXhrProgress.bind(this));
    xhr.addEventListener("timeout", this._onXhrTimeout.bind(this));
    xhr.addEventListener("error", this._onXhrError.bind(this));
    // xhr.addEventListener()
    xhr.open("post", endpoint);
    xhr.withCredentials = true;
    for (let i = 0; i < headerSetterList.length; i++) {
      headerSetterList[i](xhr, gateway, this.team, this.member, data);
    }
    xhr.setRequestHeader('content-type', 'text/plain');
    if(_.isString(data) === false){
      data = JSON.stringify(data);
    }
    xhr.send(data);
    // xhr.send(JSON.stringify(data));
  }
  _onXhrAbort = (e) =>{
    console.log('member on abort', e);
  }
  _onXhrLoad = (e) =>{
    this._isNetOk = true;
    switch (e.target.status){
      case 404:
        console.error("member request 404", this.team, this.member, this.body);
        return;
      case 500:
        console.error("member request 500", this.team, this.member, this.body);
        return;
    }
    this._responseText = e.target.responseText;
    let res;
    try{
      res = JSON.parse(e.target.responseText);
    }catch (err){
      this.dataError = err;
      this.dataErrorInfo = "json parse error";
      console.log("member json parse resonse text is", e);
      this._dispatchDataError();
      return;
    }
    this._response = res;
    let bl = checkIsOkWithModal(res, this);
    if(!bl){
      if(res.err_msg == "net error"){
        this._retry()
        return;
      }
      this._dispatchDataError();
      return ;
    }
    this._isDataOk = true;
    this._isTotallyOk = true;
    this._res = res.data;
    if(_.isFunction(this.onSuccess)){
      this.onSuccess(res.data);
    }
    this.getDispatcher().dispatchEvent(new DataEvent(this.successEventType, this));
    this._dispatchComplete();
  }
  _onXhrProgress = (e) =>{
    // console.log('member on onProgress', e);
  }
  _onXhrTimeout = (e) =>{
    console.log('member on onTimeout', e);
    this._retry();
  }
  _onXhrError = (e) =>{
    console.log('member on onNetError', e);
    this._retry();
  }
  _retry = () =>{
    this.retryCount ++;
    if(!this._canRetry()){
      if(this.netError || this.netErrorInfo){
        this._dispatchNetError();
      }else if(this.dataError || this.dataErrorInfo){
        this._dispatchDataError();
      }else{
        console.error(`unkown Member error, team is ${this.team} and member is ${this.member}`);
      }
      this._dispatchComplete();
      return;
    }
    setTimeout(()=>{
      this.send();
    }, 1000 * this.retryCount);
  }
  _dispatchComplete = () =>{
    if(_.isFunction(this.onComplete)){
      this.onComplete(this);
    }
    this.getDispatcher().dispatchEvent(new DataEvent(this.completeEventType, this));
    this._reset();
  }
  _reset = () => {
    this.retryCount = 0;
    this.dataError = null;
    this.dataErrorInfo = "";
    this.netError = null;
    this.netErrorInfo = "";
    this._isNetOk = false;
    this._isDataOk = false;
    this._isTotallyOk = false;
    this._res = null;
  }
  _dispatchNetError = ()=>{
    if(_.isFunction(this.onNetError)){
      this.onNetError(this);
    }
    this.getDispatcher().dispatchEvent(new DataEvent(this.netErrorEventType, this));
    this._dispatchComplete();
  }
  _dispatchDataError = ()=>{
    if(_.isFunction(this.onDataError)){
      this.onDataError(this);
    }
    this.getDispatcher().dispatchEvent(new DataEvent(this.dataErrorEventType, this));
    this._dispatchComplete();
  }
  _canRetry = () =>{
    return this.retryCount < this.maxRetryCount;
  }
}

function checkIsOkWithModal(res, that){
  if(!res.is_ok){
    that.dataError = new Error(res.msg);
    that.dataErrorInfo = res.msg;
    return false;
  }
  if(!res.data){
    let msg = "no result data";
    that.dataError = new Error(msg);
    that.dataErrorInfo = msg;
    return false;
  }
  if(!res.data.is_ok){
    that.dataError = new Error(res.data.msg);
    that.dataErrorInfo = res.data.msg;
    return false;
  }
  return true;
}
Member.dispatcher = null;
Member.gateway = "";
Member.headerSetterList = [];
export default Member;

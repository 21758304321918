import {EventDispatcher} from "../../../../libs/fanfanlo/events/EventDispatcher";
import {PropertyChangeEvent} from "../../../../libs/fanfanlo/events/PropertyChangeEvent";


class TrackingModel extends EventDispatcher{
    _trackingUuid:string|undefined;
    constructor() {
        super();
    }
    // static get eventTrackingUuidChanged(){return "eventTrackingUuidChanged"};
    get trackingUuid(){
        return this._trackingUuid;
    }
    set trackingUuid(value){
        if(this._trackingUuid === value)return;
        let old = this._trackingUuid;
        this._trackingUuid = value;
        let event = new PropertyChangeEvent(TrackingModel.events.eventTrackingUuidChanged, old, value, PropertyChangeEvent.kindUpdate);
        this.dispatchEvent(event);
    }

    private _openid_binded:boolean = false;
    static readonly eventOpenid_bindedChanged = "eventOpenid_bindedChanged";
    get openid_binded():boolean{
        return this._openid_binded;
    }
    set openid_binded(value:boolean){
        if(this._openid_binded === value)return;
        let old = this._openid_binded;
        this._openid_binded = value;
        let event = new PropertyChangeEvent(TrackingModel.eventOpenid_bindedChanged, old, value, PropertyChangeEvent.kindUpdate);
        this.dispatchEvent(event);
    }

    private _user_binded:boolean = false;
    static readonly eventUser_bindedChanged = "eventUser_bindedChanged";
    get user_binded():boolean{
        return this._user_binded;
    }
    set user_binded(value:boolean){
        if(this._user_binded === value)return;
        let old = this._user_binded;
        this._user_binded = value;
        let event = new PropertyChangeEvent(TrackingModel.eventUser_bindedChanged, old, value, PropertyChangeEvent.kindUpdate);
        this.dispatchEvent(event);
    }

    private _send_send_remind:boolean = false;
    static readonly eventSend_send_remindChanged = "eventSend_send_remindChanged";
    get send_send_remind():boolean{
        return this._send_send_remind;
    }
    set send_send_remind(value:boolean){
        if(this._send_send_remind === value)return;
        let old = this._send_send_remind;
        this._send_send_remind = value;
        let event = new PropertyChangeEvent(TrackingModel.eventSend_send_remindChanged, old, value, PropertyChangeEvent.kindUpdate);
        this.dispatchEvent(event);
    }
}
namespace TrackingModel{
    export enum events{
        eventTrackingUuidChanged= "eventTrackingUuidChanged"
    }
}
const trackingModel = new TrackingModel();
export {TrackingModel, trackingModel}

import Member from "../libs/fanfanlo/remotes/Member";
import {MainModelBase} from "./MainModelBase";
import {gateway} from "../confs/env";
import * as _ from "lodash"
import {DataEvent} from "../libs/fanfanlo/events/DataEvent";
import {trackingModel} from "../htmls/index/mc/tracking/TrackingModel";
import {mainModel} from "../htmls/index/mc/main/MainModel";
import {userController} from "../htmls/index/mc/user/UserController";
import {visitModel, VisitModel} from "./VisitModel";
import {storage} from "../common/storage/storage";
import {f7} from "framework7-react";
import i18n from "i18next";

function memberSetHeader(xhr, gateway, team, member, body){
    // 通过入参可以做匹配处理
    let user_token = storage.getItem("user_token")
    if(!user_token)return;
    xhr.setRequestHeader("User-Token", user_token);
}


export class MainControllerBase{

    constructor() {

    }
    start(){
        Member.dispatcher = MainModelBase.instance
        Member.gateway = gateway;
        Member.headerSetterList.push(memberSetHeader);
        visitModel.addEventListener(VisitModel.events.startInfoFetched, this._onStartInfoFetched);
        MainModelBase.instance.addEventListener(MainModelBase.events.addWaitingMember, this._onAddWaitingMemeber);
        MainModelBase.instance.addEventListener(MainModelBase.events.i18nChanged, this._onI18nChanged);
    }

    _startAll (){
        // userController.start();
        visitModel.tryLogin();
    }
    _onStartInfoFetched = (event) =>{
        this._startAll();
        this._runWaitingMembers();
    }
    _onAddWaitingMemeber=(event)=>{
        MainModelBase.instance.waitingMembers.push(event.data);
        this._runWaitingMembers();
    }
    _runWaitingMembers(){
        // if(!trackingModel.trackingUuid)return;
        if(!visitModel.visitInfo)return;
        if(MainModelBase.instance.waitingMembers.length <= 0)return;
        let runMemberFunc = MainModelBase.instance.waitingMembers.shift();
        runMemberFunc();
        setTimeout(()=>{
            this._runWaitingMembers();
        }, 50);
    }
    _onI18nChanged =(e)=>{
        f7.params.dialog.buttonCancel = i18n.t("f7.dialog.buttons.cancel");
        f7.params.dialog.buttonOk = i18n.t("f7.dialog.buttons.ok");
    }
}
import {PropertyChangeEvent} from "./PropertyChangeEvent";

export function dispatchPropertyChangeEvent(newValue:any, propertyName:string, owner:any, eventName:string, checkIsSame:boolean){
  if(checkIsSame){
    if(owner[propertyName] == newValue)return;
  }
  let oldValue = owner[propertyName]
  owner[propertyName] = newValue;
  let event = new PropertyChangeEvent(eventName, oldValue, newValue, PropertyChangeEvent.kindUpdate);
  var bl = owner.dispatchEvent(event);
}

import {UserModel, userModel} from "./UserModel";
import {Member} from "#/libs/fanfanlo/remotes/Member";
import TMs from "#/remotes/TMs";
// import {Event2} from "../../../libs/fanfanlo/events/Event2";
import {MainModel, mainModel} from "../main/MainModel";
// @ts-ignore
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
class UserController{
    _loginMember:Member|undefined;
    init =()=>{
    }
    start =()=>{
        this.addEvents();
    }
    addEvents =()=>{
        userModel.addEventListener(UserModel.events.fetchLogin, this._onFetchLogin);
    }
    loggin =(data:any)=>{

    }
    // logginByCode =(code:string, mobile:string)=>{
    //     if(this._loginMember)return;
    //     let member = this._loginMember = new Member(TMs.TVisitor, TMs.MVisitorLoginByCode, {code:code, account_type:"web", mobile:mobile}, (res:any)=>{
    //         console.log(`tms.TVisitor, tms.MVisitorLoginByCode res is`, res)
    //         userModel.dispatchEvent(new Event2(UserModel.events.fetchLogin));
    //         this._fetchCreateLoginCode();
    //     }, (member:Member)=>{
    //         console.log(`tms.TVisitor, tms.MVisitorLoginByCode net error is`, member.netError, member.netErrorInfo);
    //     }, (member:Member)=>{
    //         console.log(`tms.TVisitor, tms.MVisitorLoginByCode data error is`, member.dataError, member.dataErrorInfo);
    //     }, (member:Member) =>{
    //         this._loginMember = undefined;
    //         // console.log(`tms.TVisitor, tms.MVisitorLoginByCode complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
    //     }, null, true, true);
    //     // member.send();
    //     mainModel.dispatchEvent(new Event2(MainModel.events.addWaitingMember, member.send));
    // }
    logout =()=>{
        // userModel.setUser(undefined);
    }


    _onFetchLogin = ()=>{
        this._runLogin();
    }
    _runLogin = ()=>{
        if(this._loginMember)return;
        userModel.userConnecting = true;
        const finger = mainModel.getSavedBrowserFinger();
        const body:any = {};
        if (finger) {
            body['browser_finger'] = finger;
        }
        let member = this._loginMember = new Member(TMs.TSession, TMs.MSessionLogin, body, (res:any)=>{
            // console.log(`tms.TSession, tms.MSessionLogin res is2`, res);
            userModel.user.copyFromJson(res.user);
        }, (member:Member)=>{
            console.log(`tms.TSession, tms.MSessionLogin net error is`, member.netError, member.netErrorInfo);
        }, (member:Member)=>{
            console.log(`tms.TSession, tms.MSessionLogin data error is`, member.dataError, member.dataErrorInfo);
        }, (member:Member) =>{
            this._loginMember = undefined;
            userModel.userConnecting = false;
            userModel.userFirstConnected = true;
            // console.log(`tms.TSession, tms.MSessionLogin complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
            userModel.dispatchEvent(new DataEvent(UserModel.eventUserConnectComplete));

            this._checkUpdateBrowserFinger();
        }, null, true, true);
        // member.send();
        mainModel.dispatchEvent(new DataEvent(MainModel.events.addWaitingMember, member.send));
    }
    async _checkUpdateBrowserFinger() {
        const {now, old} = await mainModel.getBrowserFingerInfo();
        if (now === old) return;
        let member = new Member(TMs.TSession, TMs.MSessionSaveBrowserFinger, {old, now, platform: "web"}, (res:any)=>{
            // console.log(`TMs.TSession, TMs.MSessionSaveBrowserFinger res is`, res)
            userModel.dispatchEvent(new DataEvent(UserModel.eventUserBrowserFingerSaved));
        }, (member:Member)=>{
            console.log(`TMs.TSession, TMs.MSessionSaveBrowserFinger net error is`, member.netError, member.netErrorInfo);
        }, (member:Member)=>{
            console.log(`TMs.TSession, TMs.MSessionSaveBrowserFinger data error is`, member.dataError, member.dataErrorInfo);
        }, (member:Member) =>{
            // console.log(`TMs.TSession, TMs.MSessionSaveBrowserFinger complete is`, member.isNetOk, member.isDataOk, member.isTotallyOk);
        }, null, true, true);
        mainModel.dispatchEvent(new DataEvent(MainModel.events.addWaitingMember, member.send));
    }
}

export const userController = new UserController();